import React from "react"
import Layout from "../../../components/Layout/Layout"
import Container from "../../../components/Layout/Container"
import Summary from "../../../components/Epharmacy/Refill/Summary"

export default ({ location }) => (
  <Layout
    title="Review Summary"
    seoTitle="Review Summary"
    process="epharmacy"
    subtitle={
      <span>
        Please review if the following information is correct.
        <br />
        Your information will be used as a basis for your enrollment.
      </span>
    }
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <Summary />
    </Container>
  </Layout>
)
