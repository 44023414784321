const printDateValue = ({ date, month, year }) => {
  if (!month.value || !date.value || !year) return "N/A"
  return `${month.value} ${date.value} ${year}`
}

export function refillFormTicketBody(request) {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    preferredDeliveryDate,
    preferredDeliveryTime,
    paymentOption,
    orderNotes,
    latestOrderNumber,
  } = request

  return `First name: ${firstName}
  Last name: ${lastName}
  Email address: ${emailAddress}
  Mobile number: ${mobileNumber}
  Latest order reference number: ${
    latestOrderNumber ? `RF-${latestOrderNumber}` : "N/A"
  }
  Preferred Delivery Date: ${printDateValue(preferredDeliveryDate)}
  Preferred Delivery Time: ${preferredDeliveryTime}
  Payment method: ${paymentOption?.value}
  Order notes: ${orderNotes || "N/A"}
  `
}
