import React, { useContext, useState } from "react"
import Section from "../../Elements/Section"
import EditDetailsButton from "../../../components/Summary/EditDetailsButton"
import ErrorMessage from "../../Elements/ErrorMessage"
import ActionButtons from "../../Elements/ActionButtons"
import { AppContext } from "../../../context/AppContext"

import termsAndConditionsData from "../../Enrollment/utils/doctorConsentAndAuthorization.json"
import requiredFields from "./utils/requiredFields.json"
import ConsentAndAuthorization from "../../Summary/ConsentAndAuthorization"
import { sendRequest } from "../../../services/order"
import { navigate } from "gatsby"

import { refillFormTicketBody } from "./utils/refillFormZendeskTemplate"
import { refillFormConfirmation } from "./utils/refillFormConfirmationTemplate"
import { refillMessageTemplate } from "./utils/refillSmsMessageTemplate"
import RefillDetailsSummarySection from "./RefillDetailsSummarySection"
import UploadDocumentsSummary from "../../Summary/UploadedDocumentsSummary"

const Summary = () => {
  const { state, dispatch } = useContext(AppContext)
  const uploadedDocuments = state.documents
  const [isLoading, setLoading] = useState(false)
  const details = state?.epharmacy

  const hasIncompleteValues = () => {
    for (let i = 0; i < requiredFields.length; i++) {
      let keys = requiredFields[i].split(".")
      let finalValue = keys.reduce((object, key) => object[key], details)
      if (!finalValue) return true
    }
    return false
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    await sendRequest({
      flow: "refill",
      details: state,
      fileArray: uploadedDocuments,
      templateZendesk: refillFormTicketBody,
      templateConfirmation: refillFormConfirmation,
      templateTextMessage: refillMessageTemplate,
      callback: async () => {
        await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
        await dispatch({ type: "RESET_DETAILS" })
      },
    })
    await navigate("/epharmacy/completed")
  }

  const SectionBody = () => {
    if (hasIncompleteValues())
      return (
        <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
      )
    else return <RefillDetailsSummarySection details={details} />
  }

  return (
    <Section
      title="Refill Details"
      addOns={{
        right: <EditDetailsButton route="/epharmacy/refill" />,
      }}
    >
      <SectionBody />
      <UploadDocumentsSummary
        files={uploadedDocuments}
        route="/epharmacy/refill"
      />
      <ConsentAndAuthorization
        termsAndConditionsData={termsAndConditionsData}
        handleOnSubmit={handleOnSubmit}
        flow="epharmacy"
      >
        <div className="notification is-warning has-text-centered is-size-6 mt-3">
          Order is not final until verified by our pharmacist via SMS.
        </div>
        <ActionButtons
          submit={{
            label: "Submit",
            loading: isLoading,
            disabled:
              hasIncompleteValues() && !!state.epharmacy.agreeToConsent.length,
          }}
          back={{
            label: "Back",
            link: "epharmacy/refill",
          }}
        />
      </ConsentAndAuthorization>
    </Section>
  )
}

export default Summary
