const printDateValue = ({ date, month, year }) => {
  if (!month.value || !date.value || !year) return "N/A"
  return `${month.value} ${date.value} ${year}`
}

export function refillFormConfirmation(request, files, zendeskID) {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    preferredDeliveryDate,
    preferredDeliveryTime,
    paymentOption,
    orderNotes,
    latestOrderNumber,
  } = request

  const handleViewFiles = () => {
    let fileString = ""
    files.map((file) => {
      fileString += `- ${file.filename} <br>`
      return null
    })
    return fileString
  }

  const zendeskIDTemplate = zendeskID
    ? `<h3 style="text-align: center;">ePharmacy Refill Form Reference Number</h3><h3 style="text-align: center; background: #fadea4; padding: 1em; margin: auto; width: 20em;">${zendeskID}</h3>`
    : ""

  return /*html*/ `
    <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">

<head>
    <style>
        body {
            font-family: 'Lato', sans-serif;
        }

        .container {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-direction: row;
          padding: 25px;
          width: 100%;
          justify-content: center;
        }
      
        @media only screen and (min-width: 770px) {
          .content {
            width: 60%;
          }
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table,
        th,
        td {
            border: 1px solid #ccc;
        }

        td {
            padding: 10px;
        }

        th {
            text-align: left;
            padding: 10px;
        }

        .is-bordered {
            border: 1px solid #ccc;
        }

        h3 {
          margin-top: .75rem;
        }

        .terms {
          padding: 10px;
          background: #EEEEEE;
          font-size: 11px;
          color: #606060
        }

        .has-text-right {
          text-align: right
        }

        .terms th, .terms td {
          padding: 5px;
        }

        .personal-information td {
          width: 30%;
        }
    </style>
</head>

<body>
<div class="container">
  <div class="content">
    <p>Hi ${firstName} ${lastName},</p>
    <p>Thank you for ordering from CarePlus.</p>
    ${zendeskIDTemplate}
    <br />
    <p>Our pharmacist will send you an SMS to verify your order during business hours (Mon - Fri, 8:30AM-4:30PM). Please reply within 12 hours to avoid order cancellation. If you do not receive an SMS, please contact us at 0917 866 9487 as our system automatically cancels orders.</p>
    
    <p>
        <h2>Review Your Responses</h2>
        Please review your responses to make sure all details are correct.
    </p>
    <h3>Personal Information</h3>
    <table class='is-bordered personal-information'>
        <tbody>
            <tr>
                <th>Name</th>
                <td>${firstName} ${lastName}</td>
            </tr>
            <tr>
                <th>Email Address</th>
                <td>${emailAddress || "N/A"}</td>
            </tr>
            <tr>
                <th>Mobile Number</th>
                <td>${mobileNumber || "N/A"}</td>
            </tr>
            <tr>
                <th>Latest Order Reference Number</th>
                <td>${latestOrderNumber || "N/A"}</td>
            </tr>
            <tr>
                <th>Delivery Date</th>
                <td>${printDateValue(preferredDeliveryDate)}</td>
            </tr>
            <tr>
                <th>Delivery Time</th>
                <td>${preferredDeliveryTime || "N/A"}</td>
            </tr>
            <tr>
                <th>Payment Method</th>
                <td>${paymentOption.value || "N/A"}</td>
            </tr>
            <tr>
                <th>Order Notes</th>
                <td>${orderNotes || "N/A"}</td>
            </tr>
        </tbody>
    </table>

    <br>
    ${
      files.length > 0
        ? `
      <h3>Files Uploaded (${files.length})</h3>
          ${handleViewFiles()}
      </table>
    `
        : ""
    }
        
    <br />
    <p>This is an automated email. For any revisions, please reply CANCEL ORDER to the verification SMS our pharmacist will send you and place your corrected order on careplus.medgrocer.com. This is to prevent miscommunication and minimize inaccuracies.</p>

    <p>Thank you. We hope to serve you again in the future.</p>

    <p>Regards, <br /> The CarePlus Team</p>
    
    <b>Data Privacy</b>
      <ul>
        <li>The above information is correct and I give Johnson & Johnson Philippines Inc. (“J&J Philippines”) and MedGrocer the authority to collect, store, and use my information for the CarePlus Program (“Program”) and its related administrative activities. Subject to certain exceptions allowed by the law, I may revise my personal information and inform MedGrocer to update and store the same. MedGrocer may store my personal information for a period of six (6) years from the end of the program without any further consent needed from my end.</li>
        <li>J&J Philippines and MedGrocer acknowledge and respect the privacy of individuals.</li>
        <li>J&J Philippines has engaged MedGrocer to collect and hold my information in order to organize my participation in the Program and to communicate with me regarding the Program. By completing this form and providing the information requested, I consent to J&J Philippines, MedGrocer, and their partners using that information for the purposes outlined.  J&J Philippines and MedGrocer may access and disclose the information to respond to regulatory authorities or if required by law.</li>
        <li>I am not obliged to provide the information and I may choose not to. If I do not provide this information, J&J Philippines and MedGrocer may not be able to provide the needed support to me under the Program or administer the Program effectively or at all.</li>
        <li>If I would like to access, update, or correct my information or if I do not want my information retained on the database, I should contact MedGrocer.</li>
      </ul>

    <b>Program End</b>
      <ul>
        <li>I understand that J&J Philippines and MedGrocer have the right to terminate the Program prior to the end date and their representative may retrieve all the remaining communication materials.</li>
      </ul>

    <b>Waiver</b>
      <ul>
        <li>I will indemnify and hold J&J Philippines, MedGrocer, and their partners free from any liabilities that may arise as a consequence of my decision to join the Program. I also acknowledge that J&J Philippines or MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.</li>
      </ul>
    </div>
  </div>
</div>
</body>

</html>
  `
}
