import React from "react"

const formatDate = ({ month, date, year }) => {
  return `${month.value} ${date.value} ${year}`
}

const RefillDetailsSummarySection = ({ details }) => {
  return (
    <section className="mb-3">
      <div className="is-size-5">
        <p className="mb-1-mobile">
          Name:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {details.firstName} {details.lastName}
          </span>
        </p>
        <p className="mb-1-mobile">
          Email Address:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {details.emailAddress}
          </span>
        </p>
        <p className="mb-1-mobile">
          Mobile Number:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {details.mobileNumber}
          </span>
        </p>
        <p className="mb-1-mobile">
          Refill Code:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            RF-{details.latestOrderNumber}
          </span>
        </p>
        <p className="mb-1-mobile">
          Preferred Delivery Date:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {formatDate(details.preferredDeliveryDate)}
          </span>
        </p>
        <p className="mb-1-mobile">
          Preferred Delivery Time:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {details.preferredDeliveryTime}
          </span>
        </p>
        <p className="mb-1-mobile">
          Payment Method:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {details.paymentOption.value}
          </span>
        </p>
        {details.orderNotes && (
          <p className="mb-1-mobile">
            Order Notes:{" "}
            <span className="has-text-weight-bold">
              <br className="is-hidden-desktop is-hidden-tablet" />
              {details.orderNotes}
            </span>
          </p>
        )}
      </div>
    </section>
  )
}

export default RefillDetailsSummarySection
