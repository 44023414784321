import React, { Fragment } from "react"
import { Link } from "gatsby"

import Section from "../Elements/Section"
import FileThumbnail from "./UploadedDocumentsSummary/FileThumbnail"
import EditDetailsButton from "./EditDetailsButton"
import ErrorMessage from "../Elements/ErrorMessage"

const UploadDocumentsSummary = ({ files, route }) => (
  <Section
    title={`Documents Uploaded (${files.length})`}
    addOns={{
      right: <EditDetailsButton route={route} />,
    }}
  >
    {files.length < 1 ? (
      <ErrorMessage
        message={
          <Fragment>
            You have not uploaded any documents.
            <br />
            <Link to={route || "/epharmacy/upload"}>Upload Documents</Link>
          </Fragment>
        }
      />
    ) : (
      <div className="px-1 mx-1">
        <div className="columns is-vcentered is-multiline">
          {files.map((item) => (
            <div className="column is-6">
              <FileThumbnail file={item} hasFileName />
            </div>
          ))}
        </div>
      </div>
    )}
  </Section>
)

export default UploadDocumentsSummary
